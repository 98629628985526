import styled from 'styled-components';

export const AppContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: grid;
  row-gap: 16px;

  img {
    width: 100%;
    margin-bottom: 48px;
  }
`;

export const Header = styled.header`
  h2 {
    color: #333;
  }
`;

export const Footer = styled.footer``;

export const Main = styled.main``;
