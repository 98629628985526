import React from 'react';
import { Container, Line } from './styles/SectionTitle.styles';

const SectionTitle = ({ title, id }) => {
  return (
    <Container id={id}>
      <h2>{title}</h2>
      <Line />
    </Container>
  );
};

export default SectionTitle;
