import React from 'react';
import {
  Details,
  Line,
  MainContainer,
  ServiceContainer,
} from './styles/Services.styles';

import icon from './img/service-box.svg';

import services from './services.json';

const Services = () => {
  return (
    <MainContainer>
      {services.services.map((e, i) => (
        <ServiceContainer key={i}>
          <img src={icon} alt='icon' />

          <Details>
            <p>{e.title}</p>
            <Line />
            <span>{e.details}</span>
          </Details>
        </ServiceContainer>
      ))}
    </MainContainer>
  );
};

export default Services;
