import styled from 'styled-components';

export const Container = styled.div`
  /* display: none; */
  width: 100%;
  max-width: 980px;
  margin: auto;
  background-color: #1c1c1c;
  color: white;
  grid-template-columns: repeat(7, auto);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;

  a {
    color: white;
    background: none !important;
  }

  & :nth-child(1) {
    background-color: #cc4452;
  }

  & > div {
    padding: 14px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (min-width: 690px) {
    display: grid;
  }
`;
export const Item = styled.div``;
