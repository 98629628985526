import styled from 'styled-components';

export const MainContainer = styled.section`
  display: grid;

  grid-gap: 32px;
  width: 100%;
  max-width: 980px;
  margin: 0 auto 64px;

  @media (min-width: 690px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ServiceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 20px;
  color: #424242;

  p {
    margin: 0;
  }

  span {
    font-size: 15px;
  }
`;

export const Line = styled.div`
  width: 48px;
  height: 2px;
  background: #cc4452;
  margin: 16px 0;
`;
