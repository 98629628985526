import React from 'react';
import { Container } from './styles/NavBar.styles';

const NavBar = () => {
  return (
    <Container>
      <div>Home</div>
      <div>
        <a href='#booking'>Booking</a>
      </div>
      <div>
        <a href='#fleet'>Our Fleet</a>
      </div>
      <div>
        <a href='#services'>Services</a>
      </div>
      <div>
        <a href='#contact'>Terms & Policies</a>
      </div>
      <div>
        <a href='#contact'>Contact Us</a>
      </div>
    </Container>
  );
};

export default NavBar;
