import styled from 'styled-components';

export const InputField = styled.input`
  height: 40px;
  padding: 8px;
`;

export const Button = styled.div`
  height: 48px;
  width: 170px;
  background: #cc4452;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  cursor: pointer;
  color: white;
`;
