import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  border: 1px solid #e8e8e8;
  padding-bottom: 48px;

  img {
    width: 100%;
  }

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
`;

export const DetailsContainer = styled.div`
  display: grid;
  grid-row-gap: 8px;
  padding-left: 16px;
`;

export const Line = styled.div`
  width: 36px;
  height: 1px;
  background: #cc4452;
`;

export const Detail = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`;
