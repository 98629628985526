import styled from 'styled-components';

export const MainContainer = styled.section`
  width: 100%;
  background-color: #1c1c1c;
  padding: 48px 32px;
`;

export const InnerContainer = styled.div`
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: white;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 980px;
  margin: auto;

  @media (min-width: 690px) {
    justify-content: space-between;
    flex-direction: row;

    h2 {
      margin-right: 64px;
    }
  }
`;
