import styled from 'styled-components';

export const MainContainer = styled.section`
  padding: 64px 32px;
  width: 100%;
  background-color: #1c1c1c;
  p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  a {
    color: white;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 980px;
  margin: auto;
  display: grid;

  color: white;
  column-gap: 32px;
`;

export const LeftFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightFooter = styled.div`
  display: grid;
  row-gap: 16px;

  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 690px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
