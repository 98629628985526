import React from 'react';
import { Cars, MainContainer } from './styles/Fleet.styles';
import Car from './Car';
import cars from './cars.json';

const Fleet = () => {
  return (
    <MainContainer>
      <p>
        We provide access to a large fleet of vehicle sizes and types.
        Regardless of your party size, luggage or special requirements, we can
        usually provide the perfect vehicle.
      </p>
      <Cars>
        {cars.cars.map(
          (e) =>
            e.isAvailable && (
              <Car
                image={e.image}
                key={e.id}
                name={e.name}
                passengers={e.passengers}
                bags={e.bags}
              />
            )
        )}
      </Cars>
    </MainContainer>
  );
};

export default Fleet;
