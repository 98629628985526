import React from 'react';

import logo from './img/logo-vmtraveller.svg';
import {
  Container,
  LogoContainer,
  Service,
  Services,
} from './styles/IdentityBar.styles';

const IdentityBar = () => {
  return (
    <Container>
      <LogoContainer>
        <img src={logo} alt='logo' />
        <div>VM Traveller</div>
      </LogoContainer>
      <Services>
        <Service>
          <div>Airport Transfers</div>
          <div>Offered At Short Notice</div>
        </Service>
        <Service>
          <div>Airport Transfers</div>
          <div>Offered At Short Notice</div>
        </Service>
        <Service>
          <div>Airport Transfers</div>
          <div>Offered At Short Notice</div>
        </Service>
      </Services>
    </Container>
  );
};

export default IdentityBar;
