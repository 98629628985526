import React from 'react';
import { MainContainer } from './styles/Copyright.styles';

const Copyright = () => {
  return (
    <MainContainer>
      © {new Date().getFullYear()} VM Traveller Ltd. All Rights Reserved
    </MainContainer>
  );
};

export default Copyright;
