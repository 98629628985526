import styled from 'styled-components';

export const MainContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
  margin: 0 auto 64px;

  p {
    margin: 0 0 40px;
    text-align: center;
  }
`;

export const Cars = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin: 0 8px;

  @media (min-width: 690px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
