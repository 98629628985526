import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';
import ComingSoon from './pages/ComingSoon/ComingSoon';
import HomePage from './pages/HomePage/HomePage';
import Privacy from './pages/Policies/Privacy';
import Terms from './pages/Policies/Terms';

function App() {
  const comingSoon = false;

  if (comingSoon) return <ComingSoon />;

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/privacy' element={<Privacy />}></Route>
          <Route path='/terms' element={<Terms />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
