import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 980px;
  margin: 24px auto 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: none;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #424242;

  @media (min-width: 690px) {
    display: block;
  }
`;

export const SocialIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 22px;
`;

export const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    transform: scale(1.1, 1.1);
  }

  @media (min-width: 690px) {
    width: 35px;
    height: 35px;
  }
`;
