import React from 'react';
import Banner from './components/Banner/Banner';
import Copyright from './components/Copyright/Copyright';
import Fleet from './components/Fleet/Fleet';
import Footer from './components/Footer/Footer';
import IdentityBar from './components/IdentityBar/IdentityBar';
import NavBar from './components/NavBar/NavBar';
import QuoteSection from './components/QuoteSection/QuoteSection';
import SectionTitle from './components/SectionTitle/SectionTitle';
import Separator from './components/Separator/Separator';
import Services from './components/Services/Services';
import TopBarSocials from './components/TopBarSocials/TopBarSocials';
import { MainContainer } from './styles/HomePage.styles';

const HomePage = () => {
  return (
    <MainContainer>
      <TopBarSocials />
      <Separator />
      <IdentityBar />
      <NavBar />
      <SectionTitle title='Your Free Quote' id='booking' />
      <QuoteSection />
      <SectionTitle title='Our Fleet' id='fleet' />
      <Fleet />
      <Banner />
      <SectionTitle title='Our Services' id='services' />
      <Services />
      <Footer />
      <Copyright />
    </MainContainer>
  );
};

export default HomePage;
