import React from 'react';
import { Container, Detail, DetailsContainer, Line } from './styles/Car.styles';

const Car = ({ image, name, passengers, bags }) => {
  return (
    <Container>
      <img src={image} alt='car' />
      <DetailsContainer>
        <div>{name}</div>
        <Line />
        <Detail>{`${passengers} Passengers`}</Detail>
        <Detail>{`${bags} Bags`}</Detail>
      </DetailsContainer>
    </Container>
  );
};

export default Car;
