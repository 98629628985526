import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #070707;
  }
`;

export const Line = styled.div`
  width: 55px;
  height: 2px;
  background: #cc4452;
  margin-bottom: 16px;
`;
