import React, { useState } from 'react';
import { Button, InputField } from '../../styles/Shared.styles';
import { Badge, Form, MainContainer } from './styles/QuoteSection.styles';

const QuoteSection = () => {
  const initialValues = {
    pickup: '',
    dropoff: '',
    passengers: '',
    date: '',
    time: '',
    name: '',
    email: '',
    phone: '',
    requirements: '',
  };
  const [form, setForm] = useState(initialValues);

  const handleSubmit = () => {
    const body = `
    Hi, 
    
    You have a new booking from ${form.name} <${form.email}>, phone:${form.phone}. 
    
    Booking details: 
    
    Pickup:${form.pickup}
    Dropoff: ${form.dropoff}
    Passengers: ${form.passengers}
    Date: ${form.date}
    Time: ${form.time}
    Requirements: ${form.requirements}
    `;

    window.location.href = `mailto:office@vmtraveller.co.uk?subject=new quote from vmtraveller.co.uk&body=${body}`;
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <MainContainer>
      <Form>
        <Badge>Your Trip</Badge>
        <Form>
          <InputField
            placeholder='Pick Up Address'
            name='pickup'
            onChange={handleChange}
            value={form.pickup}
          />
          <InputField
            value={form.dropoff}
            placeholder='Drop Off Address'
            name='dropoff'
            onChange={handleChange}
          />
          <InputField
            value={form.passengers}
            placeholder='How Many Passengers'
            name='passengers'
            onChange={handleChange}
          />
          <InputField
            value={form.date}
            placeholder='Pick Up Date'
            name='date'
            onChange={handleChange}
          />
          <InputField
            value={form.time}
            placeholder='Pick Up Time'
            name='time'
            onChange={handleChange}
          />
        </Form>
      </Form>
      <Form>
        <Badge>You</Badge>
        <Form>
          <InputField
            value={form.name}
            placeholder='Name'
            name='name'
            onChange={handleChange}
          />
          <InputField
            value={form.email}
            placeholder='Email'
            name='email'
            onChange={handleChange}
          />
          <InputField
            value={form.phone}
            placeholder='Phone'
            name='phone'
            onChange={handleChange}
          />
          <InputField
            value={form.requirements}
            placeholder='Any special requirements?'
            name='requirements'
            onChange={handleChange}
          />
          <Button onClick={() => handleSubmit()}>Submit</Button>
        </Form>
      </Form>
    </MainContainer>
  );
};

export default QuoteSection;
