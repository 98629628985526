import styled from 'styled-components';

export const Container = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 980px;
  margin: 0 auto 30px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  /* or 117% */

  color: #424242;
`;

export const Services = styled.div`
  display: none;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 690px) {
    display: grid;
  }
`;
export const Service = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid #e8e8e8;
  padding: 0 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #424242;
`;
