import React from 'react';
import { Button } from '../../styles/Shared.styles';
import { InnerContainer, MainContainer } from './styles/Banner.styles';

const Banner = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <h2>We promise, you will have the best experience</h2>
        <Button
          onClick={() => {
            document.getElementById('booking').scrollIntoView();
          }}
        >
          Book now
        </Button>
      </InnerContainer>
    </MainContainer>
  );
};

export default Banner;
