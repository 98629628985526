import React from 'react';
import { Link } from 'react-router-dom';
import { Line } from '../SectionTitle/styles/SectionTitle.styles';
import {
  InnerContainer,
  LeftFooter,
  MainContainer,
  RightFooter,
} from './styles/Footer.styles';

const Footer = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <LeftFooter>
          <div>
            <h2>ABOUT US</h2>
            <Line />
            <p>
              We focus on customer service and strive to accommodate your unique
              needs and situation to recommend the best transportation option to
              ensure stress-free trips.
            </p>
            <p>
              We quicly reply to all client inquiries and offer moderate pricing
              so that our customers can get the high quality ride they deserve
              and arrive at their destination safely and happily.
            </p>
          </div>
        </LeftFooter>
        <RightFooter>
          <div>
            <h2>TERMS & POLICIES</h2>
            <Line />
            <div>
              <Link to='/terms'>TERMS & CONDITIONS</Link>
            </div>
            <div>
              <Link to='/privacy'>PRIVACY POLICY</Link>
            </div>
          </div>
          <div id='contact'>
            <h2>CONTACT DETAILS</h2>
            <Line />
            <p>Jennings Monument Business Park Chalgrove OX447RW</p>
            <div>office@vmtraveller.co.uk</div>
          </div>
        </RightFooter>
      </InnerContainer>
    </MainContainer>
  );
};

export default Footer;
