import styled from 'styled-components';

export const MainContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 16px 32px;
  width: 100%;
  max-width: 980px;
  margin: auto;
  background-color: #1c1c1c;
  color: white;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  column-gap: 64px;

  @media (min-width: 690px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 64px 64px;
  }
`;

export const Badge = styled.div`
  background: #cc4452;
  width: 128px;
  padding: 24px 16px 16px;
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  align-content: start;
  margin: 16px 0;

  @media (min-width: 690px) {
    margin: 0;
    row-gap: 32px;
  }
`;
