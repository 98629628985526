import React from 'react';
import {
  MainContainer,
  SocialIcon,
  SocialIcons,
  Title,
} from './styles/TopBarSocials.styles';

import whatsapp from './img/whatsapp.svg';
import facebook from './img/facebook.svg';
import instagram from './img/instagram.svg';
import linkedin from './img/linkedin.svg';
import tripadvisor from './img/tripadvisor.svg';

const TopBarSocials = () => {
  return (
    <MainContainer>
      <Title>Luxury Transport Services in Oxford</Title>
      <SocialIcons>
        <a
          href='https://wa.me/+447415438821'
          target='_blank'
          rel='noopener noreferrer'
        >
          <SocialIcon src={whatsapp} alt='whatsapp' />
        </a>
        <a
          href='https://facebook.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <SocialIcon src={facebook} alt='facebook' />
        </a>
        <a
          href='https://instagram.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <SocialIcon src={instagram} alt='instagram' />
        </a>
        <a
          href='https://www.linkedin.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <SocialIcon src={linkedin} alt='linkedin' />
        </a>
        <a
          href='https://www.tripadvisor.co.uk/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <SocialIcon src={tripadvisor} alt='tripadvisor' />
        </a>
      </SocialIcons>
    </MainContainer>
  );
};

export default TopBarSocials;
